import { Injectable } from "@angular/core";

import { MedicineProviderService } from "./medicine-provider.service";
import { BackendProvider, Collection, URLType } from "../../lib/backendProvider";
import { CacheKeyPrefix, ProviderWithCaching } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService } from "../../services/backend.service";
import { ObservableEventsService } from "../../services/observableEventsService";
import { FullPerson } from "../../models/people/fullPerson";
import { ONE_DAY, ONE_HOUR } from "../../constants";
import { LogLvl } from "../../lib/logger";
import { IMedicationAction } from "../../models/people/user";
import { RCErrorHandler } from "../../error.handler";
import { IParentMed, ParentMedProviderService } from "./parent-med-provider.service";
import { IParentTask, ParentTaskProviderService } from "./parent-task-provider.service";

export type SessionType = "meds" | "tasks";
export type MatchedSessionPropName = "matchedMedSessions" | "matchedTaskSessions";

export interface ISessionBasedWhen {
  parent?: string; // Actually an ObjectId at the server side
  recurId?: string; // ISO 8601 date string
  dtstart: string; // ISO 8601 date string
  freq?: number;
  oneOff?: boolean;
  everyVisit?: boolean;
  minDays?: number; // at least once every Y days
  maxDays?: number; // no more than once every X days
}

export interface IEventInstanceDets {
  eventId: string;
  dtstart: Date;
  recurId?: Date;
  parentEventId?: string;
}

export interface IFullSessionBasedRecordStatus {
  status?: string;
  statusPerson: string;
  statusUpdated: Date;
  statusComment?: string;

  // When actualising a med or task, the back-end would like to know the event where it was given / completed.
  // For reasons of backward compatibility, and to support the case where meds are being used without
  // scheduling, this is optional for meds
  eventDets?: IEventInstanceDets;
}

export interface ISessionBasedDataAlertSuppressed {
  person: string;
  when: Date;
  comments: string;
}

export interface IFullMedStatus extends IFullSessionBasedRecordStatus {
  status?: MedStatus;
  statusDose?: number; // Only in the event of a PRN med with units > 1
}

export interface IFullTaskStatus extends IFullSessionBasedRecordStatus {
  status?: TaskStatus;

  // The server will generally prevent tasks from being actualised if the event has not been logged-into yet.
  // We also prevent that locally (since task cards are read-only except if the carer is currently logged-into
  // the event).  However, it is possible that our log-in failed to reach the server - or failed to be processed
  // by the server.  Yet, locally, the carer IS logged in, and we don't want the server to be rejecting our
  // task status updates in this case.  Hence, this flag will ALWAYS be set to true when updating task status
  // from POC.
  allowFutureTasksToBeActualised?: boolean;
}

export interface ITopicalShape {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface ITopicalInfo {
  area?: string;
  shapes?: ITopicalShape[];
}

interface ILastCompletedInfo {
  _id?: string;
  when: {
    dtstart: Date;
    parent: string;
  };
  statusPerson?: string;
  statusPersonName?: string;
}

export interface IRawSessionBasedData {
  _id?: string;
  organisation: string;
  updatedAt?: Date;
  when: ISessionBasedWhen;
  firstoccur?: Date;
  lastCompleted?: ILastCompletedInfo; // for flexi records only, and only available in-memory as an output from expression (i.e., not saved to Mongo), indicates when the record was last completed
  person: string;
  status?: string;
  statusPerson?: string;
  statusComment?: string;
  statusUpdated?: Date;
  prevStatus?: IFullSessionBasedRecordStatus[];
  newAlert?: boolean;
  commentLen?: number;
  alertSuppressed?: ISessionBasedDataAlertSuppressed;
}

// don't add anything here...
export type MedStatus = "Taken" | "Missed" | "Left Out" | "Done" | "Asked" | "Rejected";
// ...without also adding it here
export const allMedStatusValues: MedStatus[] = ["Taken", "Missed", "Left Out", "Done", "Asked", "Rejected"];

export interface IRawMedication extends IRawSessionBasedData {
  status?: MedStatus;
  action: string;
  med: string;
  medsAction: IMedicationAction;
  units?: number;
  topical?: ITopicalInfo;
  comments?: string;
  prn?: boolean;
  monitor?: boolean;
  blisterPack?: boolean;
  statusDose?: number;
  prevStatus?: IFullMedStatus[];
  preventPrnFor?: number;
}

export interface IPendingUpdate {
  _updateCancelled?: boolean; // used to indicate that the update in progress was cancelled by the user
}

export interface IDecoratedMedication extends IRawMedication, IPendingUpdate {
  medDesc?: string;
  dose?: string;
  isCream?: boolean;
  VTMID?: string;
  asDirected?: boolean;
  updateable?: any; // keep track of whether this is a PRN that cannot be administered as there is a similar med
  lastTime?: Date;
  lastStatus?: string;
}

export type TaskStatus = "Complete" | "Unable to Complete" | "Asked";

export interface IRawTask extends IRawSessionBasedData {
  task: string;
  status?: TaskStatus;
  notes?: string;
  minCarers: number;
  prevStatus?: IFullTaskStatus[];
}

export interface IDecoratedTask extends IRawTask, IPendingUpdate {}

export interface ISessionResponsibility {
  responsibility: string;
  responsibleContact?: string;
  responsibleOtherPerson?: string;
  when: ISessionBasedWhen;
}

// These come back separately from events
export interface IBaseSessionForCarerLogin {
  shortDesc: string;
  longDesc: string;
  dtstart: Date;
  responsibilityDesc: string;
  eventId?: string;
  eventRecurId?: Date;
  eventDtstart?: Date;
  parentEventId?: string;
  // some session decorations are only added when that session is accessed from the relevant page (client-meds or
  // client-tasks).  this saves us decorating ALL of the sessions when they will usually only be accessing today's.
  // we'll use this flag to identify sessions for which the lazy decoration has been done.
  lazilyDecorated?: boolean;
}

export interface IRawMedsSessionForCarerLogin extends IBaseSessionForCarerLogin {
  meds: IRawMedication[];
}

export interface IRawTasksSessionForCarerLogin extends IBaseSessionForCarerLogin {
  tasks: IRawTask[];
}

interface IRawClientSessions {
  meds: IRawMedsSessionForCarerLogin[];
  tasks: IRawTasksSessionForCarerLogin[];
}

export interface IWithParentMedsAndTasks {
  parentMeds: { [medId: string]: IParentMed };
  parentTasks: { [taskId: string]: IParentTask };
}

export interface IOneClientSessionsResult extends IRawClientSessions, IWithParentMedsAndTasks {}

export interface IRawCareSessionsForCarerLogin {
  [clientId: string]: IRawClientSessions;
}

export interface IPreloadSessionCacheResult extends IWithParentMedsAndTasks {
  sessions: IRawCareSessionsForCarerLogin;
}

export interface IDecoratedMedsSessionForCarerLogin extends IBaseSessionForCarerLogin {
  meds: IDecoratedMedication[];
}

export interface IDecoratedTasksSessionForCarerLogin extends IBaseSessionForCarerLogin {
  tasks: IDecoratedTask[];
}

interface IDecoratedClientSessions {
  meds: IDecoratedMedsSessionForCarerLogin[];
  tasks: IDecoratedTasksSessionForCarerLogin[];
}

export interface IDecoratedCareSessionsForCarerLogin {
  [clientId: string]: IDecoratedClientSessions;
}

// These come back with the events.  It's more efficient (and means they don't need to be cached seperately, as is a
// requirement of meds for a carer login), but means they are in a more raw form than for IBaseMedsSessionForCarerLogin
export interface IBaseSessionForClientLogin {
  _id: string;
  description: string;
  dtstart: Date;
  dtend: Date;
  responsibility: ISessionResponsibility[];
  manuallyMatched?: boolean;
  earliestManuallyMatchedEventFinish?: Date; // will be omitted if there is no limit, and can be omitted if already matched
  latestManuallyMatchedEventStart?: Date; //                                  "  "
}

export interface IRawMedsSessionForClientLogin extends IBaseSessionForClientLogin {
  meds: IRawMedication[];
}

export type RawMedsSession = IRawMedsSessionForClientLogin | IRawMedsSessionForCarerLogin;

export interface IDecoratedMedsSessionForClientLogin extends IRawMedsSessionForClientLogin {
  meds: IDecoratedMedication[];
}

export interface IRawTaskSessionForClientLogin extends IBaseSessionForClientLogin {
  tasks: IRawTask[];
}

export interface IDecoratedTaskSessionForClientLogin extends IRawTaskSessionForClientLogin {
  tasks: IDecoratedTask[];
}

// leaving this in for now in case it is useful as documentation
// remove this in 2024
/*
const legacyMedicationActions = [
  { description: "Administer", trackMeds: true, displayAction: false, completionText: "Taken", notDoneText: "Missed" },
  { description: "Prompt", trackMeds: false, displayAction: true, completionText: "Done", notDoneText: "Missed" },
  { description: "Assist", trackMeds: false, displayAction: true, completionText: "Done", notDoneText: "Missed" },
];
*/

@Injectable({
  providedIn: "root",
})
export class SessionProviderService extends BackendProvider<IDecoratedClientSessions> {
  private preCachedWindow: { start: Date; end: Date };

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IDecoratedClientSessions>,
    writeCache: WriteCacheService,
    private medicineProvider: MedicineProviderService,
    private parentMedProvider: ParentMedProviderService,
    private parentTaskProvider: ParentTaskProviderService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.ClientSessions,
      Collection.Medication,
      ONE_DAY, // keep data in the cache for a maximum of 24 hours
      ONE_HOUR * 2 // but, where possible, refresh it after two hours (a conservative 'time between sessions')
    );
  }

  // duplicated in Plait app/js/services/medicinesService.ts _displayText
  public _displayText(medName: string, unitDesc: string, units: number, asDirected: boolean): string {
    let retVal;
    if (asDirected) {
      const dosageInfo = new RegExp(`(.+)\\s\\d+\\S+\\s+${unitDesc}`, "i").exec(medName);
      retVal = `${dosageInfo ? dosageInfo[1] : medName} as directed`;
    } else if (unitDesc && (units || ["tablet", "capsule"].includes(unitDesc))) {
      units = units || 1;
      const plural = units > 1 ? "s" : "";
      retVal = medName + " (" + units + " " + unitDesc + plural + ")";
    } else {
      retVal = medName;
    }
    return retVal;
  }

  // data will only be Partial<IFullSessionBasedRecordStatus> because the statusPerson is set server-side
  public async updateBackendStatus(
    itemId: string,
    time: number,
    data: Partial<IFullSessionBasedRecordStatus>,
    sessionType: SessionType
  ): Promise<void> {
    await super.cacheABackendWrite(URLType.Extended, `${itemId}/${time}/status`, data, {
      collection: sessionType === "meds" ? "medication" : "task",
    });
  }

  public updateCachedSessionDataStatus(
    cachedItem: IRawSessionBasedData,
    sessionType: SessionType,
    careWorkerId: string,
    newStatus: Partial<IFullSessionBasedRecordStatus> // Partial because we'll be setting the statusPerson
  ) {
    cachedItem.status = newStatus.status;
    if (newStatus.statusComment) {
      cachedItem.statusComment = newStatus.statusComment;
    } else {
      // in case they originally marked it as "missed"/"unable to complete" (with a reason), and then undid this
      // and marked it as "done"/"complete" instead
      delete cachedItem.statusComment;
    }
    if (sessionType === "meds") {
      const newMedStatus = newStatus as IFullMedStatus;
      const cachedMed = cachedItem as IRawMedication;
      if (newMedStatus.statusDose) {
        cachedMed.statusDose = newMedStatus.statusDose;
      } else {
        // in case they originally marked it as done (with a dose), and then undid this and marked it as
        // refused (etc.) instead
        delete cachedMed.statusDose;
      }
    }
    cachedItem.statusUpdated = newStatus.statusUpdated;
    cachedItem.statusPerson = careWorkerId;
  }

  public async findAndUpdateCachedSessionDataStatus(
    session: IBaseSessionForCarerLogin,
    serviceUserId: string,
    careWorkerId: string,
    item: IRawSessionBasedData,
    sessionType: SessionType,
    newStatus: Partial<IFullSessionBasedRecordStatus>
  ): Promise<IRawSessionBasedData> {
    const cacheKey = this.getCacheKey(serviceUserId);
    const cacheObj = await this.readCache.getNoExpiry(cacheKey);
    let clientSessions: IDecoratedClientSessions;
    let cachedSessions: IBaseSessionForCarerLogin[];
    let cachedSession: IBaseSessionForCarerLogin;
    let cachedItem: IRawSessionBasedData;
    if (cacheObj) {
      clientSessions = cacheObj.obj;
      cachedSessions = clientSessions[sessionType];
      cachedSession = cachedSessions.find((s) => new Date(s.dtstart).getTime() === new Date(session.dtstart).getTime());
      if (cachedSession) {
        const cachedItems = cachedSession[sessionType] as IRawSessionBasedData[];
        cachedItem = cachedItems.find((i) => {
          return (
            i._id === item._id ||
            // It's possible that the card they've used to update the status was created
            // from a stale cache item, and between then and when they actually performed
            // the update, that cache was refreshed.  In this case, the cache might now know
            // of this med being materialised, whilst the card is only aware of
            // its unmaterialised state
            i.when?.parent === item._id
          );
        });
        if (cachedItem) {
          this.updateCachedSessionDataStatus(cachedItem, sessionType, careWorkerId, newStatus);
          // now we've updated cachedItem, we need to replace the entire cached obj for this to
          // actually achieve anything
          await this.readCache.store(clientSessions, cacheKey);
          return cachedItem;
        }
      }
    }
    this.logger.log(
      `Cannot find existing ${sessionType} session record ${item._id} for client ${serviceUserId}`,
      LogLvl.Error
    );
    const narrative: string[] = [];
    try {
      narrative.push(`Full record: ${JSON.stringify(item)}`);
      narrative.push(`cacheKey: ${cacheKey}`);
      narrative.push(
        `total cached ${sessionType} sessions for this client: ${cachedSessions ? cachedSessions.length : "none"}`
      );
      if (cachedSessions) {
        cachedSessions.sort((a, b) => a.dtstart.valueOf() - b.dtstart.valueOf());
        narrative.push(`earliest cached session: ${cachedSessions[0].dtstart}`);
        narrative.push(`latest cached session: ${cachedSessions[cachedSessions.length - 1].dtstart}`);
      }
      narrative.push(`cachedSession: ${cachedSession ? JSON.stringify(cachedSession) : "not found"}`);
      narrative.push(`cachedItem: ${cachedItem ? JSON.stringify(cachedItem) : "not found"}\n`);
    } catch (e) {
      // ignoring any further errors raised during the generation of the error narrative
    }
    this.logger.log(narrative.join("\n************************\n"), LogLvl.Error);
    throw new Error(
      `Failed to find expected ${sessionType.toLocaleLowerCase()} session data item to update the status of in the session-provider cache ` +
        JSON.stringify(item)
    );
  }

  public async decorateAndSortMeds(rawSessions: RawMedsSession[]): Promise<{ meds: IDecoratedMedication[] }[]> {
    if (!(rawSessions?.length > 0)) {
      return;
    }
    const medIds = new Set<string>();
    for (const session of rawSessions) {
      if (!session.meds) {
        continue; // shouldn't happen
      }
      for (const med of session.meds) {
        if (!med.med) {
          continue; // "Prompt" and "Assist" (etc.) don't have a med
        }
        medIds.add(med.med);
      }
    }
    const medicines = await this.medicineProvider.getMedicinesDets([...medIds]);
    if (!medicines) {
      return; // getMedicinesDets() could return nothing if they've gone offline with really unfortunate timing
    }
    for (const session of rawSessions) {
      if (!session.meds) {
        continue; // shouldn't happen
      }
      for (const med of session.meds as IDecoratedMedication[]) {
        // not decorated yet, but they're about to be...
        if (!med.med) {
          continue; // "Prompt" and "Assist" (etc.) don't have a med
        }
        const medicine = medicines.find((m) => m._id === med.med);
        if (!medicine) {
          continue; // shouldn't happen
        }
        med.dose = medicine.dose || medicine.units?.toString();
        med.isCream = medicine.isCream;
        med.VTMID = medicine.VTMID;
        med.medDesc = this._displayText(medicine.name, med.dose, med.units, med.asDirected);
      }
    }
    // Now order the meds within each session - Blisterpack first, topical last, non blisterpack in the
    // middle, PRNs at the bottom of non blisterpack and creams, within group sorted by name
    // Keep the same as Plait back office app/compoenents/medicationSession/medsSessionModalCtrl
    for (const session of rawSessions) {
      session.meds.sort((m1: IDecoratedMedication, m2: IDecoratedMedication) => {
        const createSortingString = (med: IDecoratedMedication): string => {
          const grouping =
            (med.blisterPack ? "b" : "n") +
            (med.isCream ? "t" : "n") +
            (med.prn ? "p" : "n") +
            (med.asDirected ? "a" : "n");
          return grouping + med.medDesc;
        };
        return createSortingString(m1).localeCompare(createSortingString(m2));
      });
    }
    return rawSessions;
  }

  private async detachAndCacheImmutableData(data: IWithParentMedsAndTasks): Promise<void> {
    const promises: Promise<any>[] = [
      this.parentMedProvider.cacheRecords(data.parentMeds),
      this.parentTaskProvider.cacheRecords(data.parentTasks),
    ];
    await Promise.all(promises);
    delete data.parentMeds;
    delete data.parentTasks;
  }

  /**
   * Preload the cache with decorated med and task sessions for each of the clients whose
   * id appears in a given list, overwriting any data already in the cache.  The start and end
   * dates provided ensure that sessions covering at least this period will be returned -
   * depending upon Plait configuration, a wider range of sessions may be returned.
   * @param clientIds The clients whose data is to be cached requested
   * @param start The earliest time of a returned med/task session
   * @param end The latest time of a returned med/task session
   */
  public async preloadSessionCache(
    clientIds: string[],
    start: Date,
    end: Date,
    allCacheKeys: string[]
  ): Promise<IDecoratedCareSessionsForCarerLogin> {
    this.logger.log(`preloadSessionCache(): start=${start}; end=${end}`, LogLvl.Light);
    let haveParentMedIds = ProviderWithCaching.getRecordIdsFromCacheKeys(
      allCacheKeys,
      this.parentMedProvider.cacheKeyPrefix
    );
    let haveParentTaskIds = ProviderWithCaching.getRecordIdsFromCacheKeys(
      allCacheKeys,
      this.parentTaskProvider.cacheKeyPrefix
    );

    // Divide the query string limit by 25 as we are dealing in comma delimited 24 char ids
    const queryLimit = 3000 / 25 - clientIds.length;
    if (haveParentMedIds.length + haveParentTaskIds.length > queryLimit) {
      haveParentMedIds = []
      if (haveParentMedIds.length + haveParentTaskIds.length > queryLimit) {
        haveParentMedIds = []
      }
    }

    const url =
      `multiClientSessionsForPhone/${clientIds.join(",")}` +
      `/${start.getTime()}/${end.getTime()}?` +
      `parentStore=1&` +
      `haveParentMedIds=${haveParentMedIds}&` +
      `haveParentTaskIds=${haveParentTaskIds}`;
    const results: IPreloadSessionCacheResult = await this.getFromBackEnd(URLType.Extended, url);
    if (!results) {
      return; // offline
    }
    await this.detachAndCacheImmutableData(results);
    // now we've removed the parentMeds and parentTasks, all we should be left with is the sessions, in the form { [clientId]: { meds, tasks } }
    const rawSessions = results.sessions;
    // remember the window that we have pre-cached so we can request at least that much data when the cached
    // data needs to be refreshed
    this.preCachedWindow = { start, end };
    // make sure we are not down-dating records that we have not been able to write back yet...
    this.logger.log(
      "data received from multiClientSessionsForPhone.  beginning preserveLocalUpdatesAcrossAllClients process...",
      LogLvl.Light
    );
    await this.preserveLocalUpdatesAcrossAllClients(rawSessions);
    const keys = Object.keys(rawSessions);
    this.logger.log(`${keys.length} clients' session data retrieved.  Beginning med decoration...`, LogLvl.Light);
    // combine each of the clients' med sessions into a single array, ready for decoration and sorting
    const allClientsMedSessions: IRawMedsSessionForCarerLogin[] = [];
    for (const clientId of keys) {
      const rawClientSessions = rawSessions[clientId];
      if (rawClientSessions.meds) {
        allClientsMedSessions.push(...rawClientSessions.meds);
      }
    }
    await this.decorateAndSortMeds(allClientsMedSessions);
    this.logger.log(`${allClientsMedSessions.length} sessions decorated.  Beginning to cache data...`, LogLvl.Light);
    for (const clientId of keys) {
      await this.readCache.store(rawSessions[clientId], this.getCacheKey(clientId));
    }
    this.logger.log(`preloadSessionCache() finished caching.`, LogLvl.Light);
    return rawSessions;
  }

  private async cacheImmutablesDecorateMedsAndPreserveLocalUpdates(
    clientId: string,
    incomingData: IOneClientSessionsResult,
    cachedSessions: IDecoratedClientSessions
  ): Promise<IRawClientSessions> {
    await this.detachAndCacheImmutableData(incomingData);
    // now we've removed the parentMeds and parentTasks, all we should be left with is the sessions, in the form { meds, tasks }
    const incomingSessions = incomingData as IRawClientSessions;
    // make sure we are not down-dating records that we have not been able to write back yet...
    await this.preserveLocalUpdatesForOneClient(clientId, incomingSessions);
    await this.decorateAndSortMeds(incomingSessions.meds);
    // before we return the decorated incoming sessions, remove properties from the already-decorated
    // cached sessions (if any) that might have been added since that decoration was done.  without doing this,
    // our caller (getChangeableId) will ALWAYS find that the incoming data is different to the cached data,
    // so it will always yield twice, resulting in a potentially-unnecessary screen refresh
    if (cachedSessions?.meds) {
      for (const session of cachedSessions.meds) {
        for (const med of session.meds) {
          // these three properties are all added to meds by client-meds-page (updateable, by checkPRNMeds(), and
          // lastTime and lastStatus by addLastAdministeredDatesToCurrentSession()).  there is no harm in us
          // deleting them here - they will be (re)applied at the end of the initialise() function of that
          // page (after its data has passed through this function)
          delete med.updateable;
          delete med.lastTime;
          delete med.lastStatus;
        }
      }
    }
    return incomingSessions;
  }

  /**
   * Return, using raw data retrieved either from the cache, the backend, or both, the meds and tasks required
   * by a single client across a particular window.  This will consist of a list of "sessions": information about
   * the medications to be taken by the client / the tasks needing to be completed, keyed against the
   * date/time when this is due to happen.
   * It is left up to the backend to decide exactly how many sessions will be returned - this
   * will be determined by options specified in Plait - but if we have previously cached data for a particular
   * window, we will ask the backend to provide data for a window at least as wide as that.
   * @param clientId The client whose sessions are required
   * @param carerId The carer who is logged in and for whom the sessions are being requested.  In the case
   * of a non-authenticated (or indeed, just non-carer login), this can be omitted
   */
  public async *getCareSessionsForClient(
    clientId: string,
    carerId: string,
    allCacheKeys: string[]
  ): AsyncIterableIterator<IDecoratedClientSessions> {
    this.logger.log(`getCareSessionsForClient(${clientId})`, LogLvl.Light);
    let url = `oneClientSessionsForPhone/%%`;
    // if we have been pre-cached between two dts, add these to the query string so the server will honour
    // that window as a minimum (it might return more, depending upon the sessionsBeforeCurrent /
    // sessionsAfterCurrent configuration)
    const queryStringDictionary: { [key: string]: string } = {
      parentStore: "1",
      haveParentMedIds: ProviderWithCaching.getRecordIdsFromCacheKeys(
        allCacheKeys,
        this.parentMedProvider.cacheKeyPrefix
      ).join(","),
      haveParentTaskIds: ProviderWithCaching.getRecordIdsFromCacheKeys(
        allCacheKeys,
        this.parentTaskProvider.cacheKeyPrefix
      ).join(","),
    };
    if (this.preCachedWindow) {
      queryStringDictionary.start = this.preCachedWindow.start.valueOf().toString();
      queryStringDictionary.end = this.preCachedWindow.end.valueOf().toString();
    }
    if (carerId) {
      queryStringDictionary.carerId = carerId;
    }
    const keys = Object.keys(queryStringDictionary);
    for (let i = 0; i < keys.length; i++) {
      url += `${i === 0 ? "?" : "&"}${keys[i]}=${queryStringDictionary[keys[i]]}`;
    }
    const iterator = super.getChangeableId(clientId, URLType.Extended, url, {
      preCallback: this.cacheImmutablesDecorateMedsAndPreserveLocalUpdates.bind(this, clientId),
    });
    for await (const sessions of iterator) {
      yield sessions.data;
    }
    this.logger.log(`getCareSessionsForClient(${clientId}) - end.`, LogLvl.Light);
  }

  public async getCachedSessionsForClient(clientId: string): Promise<IDecoratedClientSessions> {
    const sessions = await this.getCached(this.getCacheKey(clientId));
    if (!sessions) {
      throw new Error(`No cached sessions found for client ${clientId}`);
    }
    return sessions.obj;
  }

  private async preserveLocalUpdatesForOneClient(
    clientId: string,
    incomingSessions: IRawClientSessions
  ): Promise<IRawClientSessions> {
    if (incomingSessions.meds || incomingSessions.tasks) {
      const cacheKey = this.getCacheKey(clientId);
      const cachedItem = await this.readCache.get(cacheKey);
      if (cachedItem) {
        for (const sessionType of ["meds", "tasks"] as SessionType[]) {
          if (!incomingSessions[sessionType]) {
            continue;
          }
          const cached = cachedItem.obj[sessionType] as IBaseSessionForCarerLogin[];
          if (!cached) {
            return;
          }
          let retained = 0;
          for (const incomingSession of incomingSessions[sessionType]) {
            const incomingSessionDt = (incomingSession.eventRecurId || incomingSession.dtstart).valueOf();
            const cachedSession = cached.find((cs) => {
              return (
                cs.eventId === incomingSession.eventId &&
                (cs.eventRecurId || cs.dtstart).valueOf() === incomingSessionDt &&
                // match on longDesc if we have them.  If we don't do this, there will be more than one cachedSessions
                // matching the details of incomingSession in the case where two sessions have matched to the same event
                (!cs.longDesc || !incomingSession.longDesc || cs.longDesc === incomingSession.longDesc)
              );
            });
            if (!cachedSession) {
              continue;
            }
            const incomingSessionData = incomingSession[sessionType] as IRawSessionBasedData[];
            const cachedSessionData = cachedSession[sessionType] as IRawSessionBasedData[];
            incomingSessionData.forEach((incomingMedOrTask, i: number) => {
              const incomingDt = (incomingMedOrTask.when.recurId || incomingMedOrTask.when.dtstart).valueOf();
              const cachedMedOrTask = cachedSessionData.find((csd) => {
                if (sessionType === "meds") {
                  if ((csd as IRawMedication).med !== (incomingMedOrTask as IRawMedication).med) {
                    return false;
                  }
                } else if (sessionType === "tasks") {
                  if ((csd as IRawTask).task !== (incomingMedOrTask as IRawTask).task) {
                    return false;
                  }
                }
                return (csd.when.recurId || csd.when.dtstart).valueOf() === incomingDt;
              });
              if (!cachedMedOrTask) {
                return;
              }
              if (
                cachedMedOrTask.statusUpdated &&
                (!incomingMedOrTask.statusUpdated ||
                  new Date(cachedMedOrTask.statusUpdated).valueOf() >
                    new Date(incomingMedOrTask.statusUpdated).valueOf())
              ) {
                // we have a more recent copy on the phone
                incomingSessionData[i] = cachedMedOrTask;
                retained++;
              }
            });
          }
          if (retained > 0) {
            this.logger.log(
              `Retained ${retained} cached ${sessionType} that have more recent local updates`,
              LogLvl.Heavy
            );
          }
        }
      }
    }
    return incomingSessions;
  }

  private async preserveLocalUpdatesAcrossAllClients(sessions: IRawCareSessionsForCarerLogin): Promise<void> {
    for (const clientId in sessions) {
      await this.preserveLocalUpdatesForOneClient(clientId, sessions[clientId]);
    }
  }

  /**
   * Retrieve all of the reasons previously used when the given med was taken
   * by (or not taken by) the given client.
   * This information is not currently cached, so all calls hit the backend.
   * @param api Which med reason api to use ('Missed', 'LeftOut' etc.)
   * @param client Which client did, or was due to receive the medication?
   * @param medId What medication?
   */
  public getPastMedDeliveryReasons(api: string, client: FullPerson, medId: string): Promise<string[]> {
    if (!client?._id) {
      return Promise.resolve([]);
    }
    // Here, we remove any spaces.  As long as the words either side of the space
    // are capitalized (so 'Left Out' becomes 'LeftOut'), then it will be
    // correctly converted back at the other end (using lo-dash's startCase method)
    const url = `reasons/${api.replace(" ", "")}/${client._id}/${medId}`;
    return this.getFromBackEnd(URLType.Extended, url, { collection: "medication" });
  }

  public getPastTaskDeliveryReasons(status: TaskStatus, client: FullPerson, taskId: string): Promise<string[]> {
    let reasonsForWhat: string;
    if (status === "Unable to Complete") {
      reasonsForWhat = "UnableToComplete";
    } else {
      throw new Error(`Task status "${status}" is not expected to require a reason`);
    }
    const url = `reasons/${reasonsForWhat}/${client._id}/${taskId}`;
    return this.getFromBackEnd(URLType.Extended, url, { collection: "task" });
  }
}
