import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/capacitor";

import { DataSource, URLType } from "../../lib/backendProvider";
import { CacheKeyPrefix, ProviderWithCaching } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService, ILastUpdated } from "../../services/backend.service";
import { LoggingMethod, User } from "../../models/people/user";
import { ObservableEventsService } from "../../services/observableEventsService";
import { EventTypeProviderService } from "./event-type-provider.service";
import { PersonProviderService } from "./person-provider.service";
import { ICombinedTaskCompletions } from "../../models/events/eventLogging";
import { HistoricEventLogProviderService } from "./historic-event-logs-provider.service";
import { ONE_DAY, ONE_MINUTE } from "../../constants";
import { LogLvl } from "../../lib/logger";
import { CurrentClientProviderService } from "./current-client-provider.service";
import { NamedPerson } from "../../models/people/current-client";
import { CareplanProviderService } from "./careplan-provider.service";
import { RCErrorHandler } from "../../error.handler";
import { findCarerAllocAddingOneForRunAssignedCarers } from "../../models/events/eventUtils";
import { RunProviderService } from "./run-provider.service";
import { ParentEventProviderService } from "./parent-event-provider.service";
import { UtilsService } from "../../services/utilsService";
import { CachedRecordIds } from "../../lib/immutableBackendProvider";
import {
  IBaseSessionForCarerLogin,
  IDecoratedMedsSessionForCarerLogin,
  IDecoratedTasksSessionForCarerLogin,
  IFullSessionBasedRecordStatus,
  IRawSessionBasedData,
  MatchedSessionPropName,
  SessionProviderService,
  SessionType,
} from "./session-provider.service";
import {
  BaseEventProviderService,
  ExpressedEventClass,
  ICarerAllocation,
  IEventLog,
  IExpressedClientAllocation,
  IExpressedEvent,
  IExpressedEventsAndStores,
} from "./event-provider.service";

export interface IExpressedClientAllocationForCarerLogin extends IExpressedClientAllocation {
  matchedMedSessions?: IDecoratedMedsSessionForCarerLogin[];
  matchedTaskSessions?: IDecoratedTasksSessionForCarerLogin[];
}

export interface IExpressedCarerEvent extends IExpressedEvent {
  clients: IExpressedClientAllocationForCarerLogin[];
}

export interface IDatesByClient {
  [clientId: string]: Date;
}

@Injectable({
  providedIn: "root",
})
export class CarerEventProviderService extends BaseEventProviderService<IExpressedCarerEvent[]> {
  // Keep track of the earliest and latest dates between which event data has been requested during this session.
  // Whenever we hit the backend, we will always search as least as wide as cachedFrom -> cachedTo,
  // thus ensuring that an event that was at some point in the cache is not removed from the cache
  // due to a narrower range of dates being requested.
  // These dates should be reset each time the user logs in, by a call to resetCacheWindow()
  private cachedFrom: Date;
  private cachedTo: Date;
  private adHocCount: number;

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IExpressedCarerEvent[]>,
    writeCache: WriteCacheService,
    utils: UtilsService,
    currentClientProvider: CurrentClientProviderService,
    eventTypeProvider: EventTypeProviderService,
    parentEventProvider: ParentEventProviderService,
    personProvider: PersonProviderService,
    runProvider: RunProviderService,
    private sessionProvider: SessionProviderService,
    private historicEventLogProvider: HistoricEventLogProviderService,
    private careplanProvider: CareplanProviderService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.CarerEvents,
      utils,
      currentClientProvider,
      eventTypeProvider,
      parentEventProvider,
      personProvider,
      runProvider
    );
    this.adHocCount = -1; // We don't know yet, but want to prevent cache clearances until we do
    this.initialiseAdHocCount().catch((e) =>
      this.errorHandler.handleUnexpected(e, ["CarerEventProviderService", "constructor", "initialiseAdHocCount"])
    );
    // DON'T subscribe to "event:updated", because in the case of a carer log-in / log-out, we need to
    // respond asynchronously, and subscriptions do not support this.  instead, everything that publishes
    // "event:updated" should also call (and await) our handleEventUpdate method
    this.observableEvents.subscribe("backend:refresh:/apix/event/adhoc", (data) => {
      this.handleAdhocUpdate(data).catch((e) =>
        this.errorHandler.handleUnexpected(e, ["CarerEventProviderService", "constructor", "handleAdhocUpdate"])
      );
    });
  }

  public resetCacheWindow() {
    this.cachedFrom = undefined;
    this.cachedTo = undefined;
  }

  protected cacheDesc(): string {
    return "carer";
  }

  public async *retrieveEventList(
    carerId: string,
    start: Date,
    end: Date,
    force = false,
    dateUpdatedObj?: ILastUpdated
  ): AsyncIterableIterator<IExpressedCarerEvent[]> {
    const allKeys = await this.getAllCacheKeys();

    const eventTypeIds = ProviderWithCaching.getRecordIdsFromCacheKeys(allKeys, this.eventTypeProvider.cacheKeyPrefix);
    const eventTypeIdsAndTimestamps = await this.eventTypeProvider.getCachedRecordIdsAndTimestamps(eventTypeIds);

    const personIds = ProviderWithCaching.getRecordIdsFromCacheKeys(allKeys, this.personProvider.cacheKeyPrefix);
    const personIdsAndTimestamps = await this.personProvider.getCachedRecordIdsAndTimestamps(personIds);

    const runIds = ProviderWithCaching.getRecordIdsFromCacheKeys(allKeys, this.runProvider.cacheKeyPrefix);
    const runIdsAndTimestamps = await this.runProvider.getCachedRecordIdsAndTimestamps(runIds);

    const parentEventIds = ProviderWithCaching.getRecordIdsFromCacheKeys(
      allKeys,
      this.parentEventProvider.cacheKeyPrefix
    );

    for await (const cachedEventList of this.getEvents(
      carerId,
      start,
      end,
      force,
      dateUpdatedObj,
      allKeys,
      eventTypeIdsAndTimestamps,
      personIdsAndTimestamps,
      runIdsAndTimestamps,
      parentEventIds
    )) {
      if (!cachedEventList) {
        yield; // no network
      } else {
        // As the cache may contain more data than we need right now, filter out anything that does not
        // overlap start => end
        yield cachedEventList.data.filter((e) => {
          const eventStart = new Date(e.plannedStart).getTime();
          const eventFinish = eventStart + e.plannedDuration * ONE_MINUTE;
          return eventStart <= end.getTime() && eventFinish > start.getTime();
        });
      }
    }
  }

  protected getEvents(
    carerId: string,
    start: Date,
    end: Date,
    forceCheckBackend: boolean,
    dateUpdatedObj: ILastUpdated,
    allCacheKeys: string[],
    haveEventTypes: CachedRecordIds,
    havePeople: CachedRecordIds,
    haveRuns: CachedRecordIds,
    haveParentEventIds: string[]
  ): AsyncIterableIterator<{ data: IExpressedCarerEvent[]; source: DataSource }> {
    // As the data will (when first requested) be written to a cache, which will be used
    // exclusively if the carer loses network, we need to make sure we have cached
    // plenty of data.  There is no point caching more than 1 extra day however, since the
    // cache is only allowed to exist for 1 day.
    const extraDaysToCache = 1;
    let cacheFrom = start;
    let cacheTo = new Date(end.valueOf() + ONE_DAY * extraDaysToCache);
    if (!this.cachedFrom || this.cachedFrom > cacheFrom) {
      this.cachedFrom = cacheFrom;
    } else if (this.cachedFrom < cacheFrom) {
      cacheFrom = this.cachedFrom;
    }
    if (!this.cachedTo || this.cachedTo < cacheTo) {
      this.cachedTo = cacheTo;
    } else if (this.cachedTo > cacheTo) {
      cacheTo = this.cachedTo;
    }

    let haveEventTypeIdsString = JSON.stringify(haveEventTypes);
    let havePersonIdsString = JSON.stringify(havePeople);
    let haveRunIdsString = JSON.stringify(haveRuns);
    const queryLimit = 3000;
    if (haveEventTypeIdsString.length + havePersonIdsString.length + haveRunIdsString.length + 25 * haveParentEventIds.length > queryLimit) {
      havePersonIdsString = '[]';
      if (haveEventTypeIdsString.length + havePersonIdsString.length + haveRunIdsString.length + 25 * haveParentEventIds.length > queryLimit) {
        haveEventTypeIdsString = '[]';
        if (haveEventTypeIdsString.length + havePersonIdsString.length + haveRunIdsString.length + 25 * haveParentEventIds.length > queryLimit) {
          haveRunIdsString = '[]';
          if (haveEventTypeIdsString.length + havePersonIdsString.length + haveRunIdsString.length + 25 * haveParentEventIds.length > queryLimit) {
            haveParentEventIds = [];
          }
        }
      }
    }

    const urlPattern =
      `carer/${cacheFrom.getTime()}/${cacheTo.getTime()}?` +
      `carerId=${carerId}&` +
      // a value of 2 for the returnStores parameter will get us the runs
      // (as well as the event types and people, which are all that is provided with a value of 1)
      `returnStores=2&` +
      `haveEventTypeIds=${haveEventTypeIdsString}&` +
      `havePersonIds=${havePersonIdsString}&` +
      `haveRunIds=${haveRunIdsString}&` +
      `sortEvents=1&` +
      // we don't want the events to be decorated, because we can save bandwidth by doing this ourselves
      `decorate=0&` +
      `includeLogs=1&` +
      `includeComments=1&` +
      // for series instances where certain details do not differ from those of the parent event, we can save
      // bandwidth by requesting that those details be provided seperately, in a store keyed off the parent event id,
      // and we can provide a list of the parent event ids whose details we already have to further reduce bandwidth
      // requirements.  The data that is transferred in this way when the parentStore parameter is provided can be
      // seen in parent parent-event-provider.service.ts
      `parentStore=1&` +
      `haveParentEventIds=${haveParentEventIds}&` +
      `mobileRequest=1`;
    return super.getChangeableId(carerId, URLType.Extended, urlPattern, {
      forceCheckBackend,
      preCallback: this.populateSessionsCacheAndAddToEventsWhilePreservingUpdated.bind(
        this,
        cacheFrom,
        cacheTo,
        carerId,
        dateUpdatedObj,
        allCacheKeys
      ),
      postCallbacks: [this.populateEventLogsCache.bind(this), this.populateCareplanCache.bind(this)],
    });
  }

  private insertAdHocInList(adHoc: IExpressedEvent, eventList: IExpressedEvent[]): void {
    const ahStart = new Date(adHoc.plannedStart).valueOf();
    const cachePos = eventList.findIndex((ev) => new Date(ev.plannedStart).valueOf() < ahStart);
    eventList.splice(cachePos, 0, adHoc);
  }

  public async createAndCacheAdHocEvent(
    carerId: string,
    serviceUser: NamedPerson,
    eventTypeId: string
  ): Promise<IExpressedEvent> {
    const now = new Date();
    const e: IExpressedEvent = {
      plannedStart: now,
      plannedDuration: 0,
      clients: [
        {
          person: serviceUser._id,
          familyName: serviceUser.familyName,
          givenName: serviceUser.givenName,
          preferredName: serviceUser.preferredName,
          cp: { starts: null },
        },
      ],
      carers: [
        {
          person: carerId,
          informed: { manual: now },
        },
      ],
      type: eventTypeId,
      eventClass: ExpressedEventClass.AdHoc,
      logs: [],
      uniqueEventId: "-" + now.valueOf(),
      eventId: "-" + now.valueOf(),
    };
    const cacheKey = this.cacheKeyPrefix + "." + carerId;
    const list = await this.readCache.get(cacheKey);
    this.insertAdHocInList(e, list.obj);
    await this.readCache.store(list.obj, cacheKey);
    this.adHocCount += 1;
    this.observableEvents.publish("updateCacheStatus");
    return e;
  }

  public async sendAdHocEventToBackend(expEvent: IExpressedEvent, carerAlloc: ICarerAllocation, logs: IEventLog[]) {
    this.logger.log(`Sending ad hoc event to back end`, LogLvl.Heavy);
    const event = {
      when: {
        dtstart: carerAlloc.actualStart.time,
        oneOff: true,
      },
      mins:
        (new Date(carerAlloc.actualFinish.time).valueOf() - new Date(carerAlloc.actualStart.time).valueOf()) /
        ONE_MINUTE,
      type: expEvent.type,
      carers: [
        {
          person: carerAlloc.person,
          actualStart: carerAlloc.actualStart,
          actualFinish: carerAlloc.actualFinish,
          informed: { detailView: carerAlloc.actualStart.time },
        },
      ],
      clients: [
        {
          person: expEvent.clients[0].person,
        },
      ],
      logs: logs.map((l) => {
        l.submissionDate = l.submissionDate || new Date();
        return l;
      }),
    };
    // eslint-disable-next-line no-console
    console.log("CACHE THE UPDATE?  Need to add test on 020-direct-assignment");
    await super.cacheABackendWrite(URLType.Extended, "adhoc", event);
  }

  public async handleAdhocUpdate(data): Promise<void> {
    const updatedEvent: IExpressedEvent = {
      plannedStart: data.when.dtstart,
      plannedDuration: data.mins,
      eventClass: ExpressedEventClass.OneOff,
      type: data.type,
      clients: data.clients,
      carers: data.carers,
      eventId: data._id,
      comments: data.comments,
      logs: data.logs,
      uniqueEventId: data._id,
    };
    // Very similar to the much more commented handleEventUpdate()
    const allCachedEvents = await this.getAllCached();
    for (const oneCarersEvents of allCachedEvents) {
      const eventIndex = oneCarersEvents.obj.findIndex((e) => {
        return (
          e.eventClass === "AdHoc" &&
          e?.carers[0]?.actualStart?.time.valueOf() === new Date(updatedEvent.plannedStart).valueOf()
        );
      });
      if (eventIndex > -1) {
        const carerId = oneCarersEvents.key.slice(this.cacheKeyPrefix.length + 1);
        const carerStillAssigned = !!updatedEvent.carers.find((c) => c.person && c.person === carerId);
        if (carerStillAssigned) {
          Object.assign(updatedEvent.clients[0], oneCarersEvents.obj[eventIndex].clients[0]);
          oneCarersEvents.obj[eventIndex] = updatedEvent;
          oneCarersEvents.lastRead = new Date().valueOf() - 11 * ONE_MINUTE; // Force a re-read
        } else {
          oneCarersEvents.obj.splice(eventIndex, 1);
        }
        // Update the carer's cached event list in localstorage now we have modified it
        await this.readCache.store(oneCarersEvents.obj, oneCarersEvents.key);
        this.adHocCount -= 1;
        this.observableEvents.publish("updateCacheStatus");
      }
    }
  }

  private handleOneSessionType(
    carerEvent: IExpressedCarerEvent,
    sessions: IBaseSessionForCarerLogin[],
    destArray: IBaseSessionForCarerLogin[],
    sessionType: SessionType,
    eventUpdated: { meds: boolean; tasks: boolean }
  ): void {
    if (!sessions) {
      return;
    }
    for (const s of sessions) {
      if (
        s.eventId &&
        (s.eventId === carerEvent.eventId ||
          (s.eventId === carerEvent.parentId && s.eventRecurId.valueOf() === carerEvent.instanceStart.valueOf()))
      ) {
        destArray.push(s);
        eventUpdated[sessionType] = true;
      }
    }
  }

  public async populateSessionsCacheAndAddToEventsWhilePreservingUpdated(
    start: Date,
    end: Date,
    carerId: string,
    dateUpdated: ILastUpdated,
    allCacheKeys: string[],
    incoming: IExpressedEventsAndStores,
    cached: IExpressedCarerEvent[]
  ): Promise<IExpressedCarerEvent[]> {
    const eventList: IExpressedCarerEvent[] = await this.detachAndCacheImmutableData(incoming);
    await super.decorateEvents(eventList);
    // our carer should be assigned to every event in eventList, but some of those allocations may be by run.  within
    // this app, we give them their own direct allocation from the get-go (even before they have logged in), and we
    // don't bother to remove their entry from the allocatedByRun array when doing this.  events on a double-up run
    // will probably have three allocations by the time this loop has completed - this is NOT how the data is stored
    // at the backend, but is more convenient within this app.
    for (const incomingEvt of eventList) {
      findCarerAllocAddingOneForRunAssignedCarers(incomingEvt, carerId);
    }
    const clients = this.getUniqueClientIds(eventList);
    if (clients.length > 0) {
      const allClientSessions = await this.sessionProvider.preloadSessionCache(clients, start, end, allCacheKeys);
      // preloadSessionCache will return nothing if they've gone offline, so we need to check...:
      if (allClientSessions) {
        for (const incomingEvt of eventList) {
          if (!incomingEvt.clients) {
            continue;
          }
          const eventUpdated = { meds: false, tasks: false };
          for (const client of incomingEvt.clients) {
            client.matchedMedSessions = [];
            client.matchedTaskSessions = [];
            if (client.person) {
              const thisClientSessions = allClientSessions[client.person];
              if (thisClientSessions) {
                this.handleOneSessionType(
                  incomingEvt,
                  thisClientSessions.meds,
                  client.matchedMedSessions,
                  "meds",
                  eventUpdated
                );
                this.handleOneSessionType(
                  incomingEvt,
                  thisClientSessions.tasks,
                  client.matchedTaskSessions,
                  "tasks",
                  eventUpdated
                );
              }
            }
          }
          // Though the event cache will be updated with the session match(es) we've just
          // made once we return from this method, a page may already be displaying this
          // event's details, and in that case, it needs to know about these matches too
          if (eventUpdated.meds) {
            this.observableEvents.publish("event:matchedMedSessions:updated", incomingEvt);
          }
          if (eventUpdated.tasks) {
            this.observableEvents.publish("event:matchedTaskSessions:updated", incomingEvt);
          }
        }
      }
    }
    // Now check whether we have more up-to-date information for any of the events than the server has just provided
    // us with.  We need to check each of the things that can be updated locally: the informed timestamps, actual
    // starts and finishes, and the logs.  Any or all of these could be more up-to-date than the data held by the
    // server if a write crossed over with the incoming data, or the carer had no signal when they logged-into or out
    // of an event.  (Theoretically it is also possible that the carer's log in/out was rejected by the back-end, but
    // this shouldn't ever happen.)
    if (cached) {
      for (const incomingEvt of eventList) {
        let cachedEvent = cached.find((c) => c.uniqueEventId === incomingEvt.uniqueEventId);
        if (!cachedEvent && incomingEvt.parentId && incomingEvt.instanceStart) {
          // if we don't have a cached event with the same id, it is possible that the event is unmaterialised in
          // our cache, but has since been materialised at the back-end (presumably, when another carer logged in).
          // in that case, we should look for the unmaterialised instance in our cache...
          cachedEvent = cached.find(
            (c) =>
              c.parentId === incomingEvt.parentId && c.instanceStart?.valueOf() === incomingEvt.instanceStart.valueOf()
          );
        }
        // when a carer logs out of an event, any log they have written gets added to the logs array of the event so they
        // would be able to access it again through the event before the cache is next refreshed.  the problem is... if the
        // cache is refreshed before that log has made it to the back-end, it will just get overwritten, which
        // is what we will attempt to avoid here:
        if (cachedEvent?.logs) {
          for (const log of cachedEvent.logs) {
            if (!log.editing) {
              continue;
            }
            if (!incomingEvt.logs) {
              incomingEvt.logs = [];
            }
            if (
              !incomingEvt.logs.some((l) => l.author === log.author && l.subject === log.subject && l.log === log.log)
            ) {
              incomingEvt.logs.push(log);
            }
          }
        }
        // next we'll deal with old-style tasks
        this.mergeOldStyleTaskCompletions(cachedEvent, incomingEvt, carerId);
        // everything else we need to check are properties of carer allocations, so if we don't have any carers
        // (which shouldn't happen) then we can stop here
        if (!incomingEvt.carers || !cachedEvent?.carers) {
          continue;
        }
        const cachedAlloc = cachedEvent.carers.find((c) => c.person === carerId);
        if (!cachedAlloc) {
          continue; // shouldn't happen (see call to findCarerAllocAddingOneForRunAssignedCarers, above)
        }
        const incomingAllocIdx = incomingEvt.carers.findIndex((c) => c.person === carerId);
        const incomingAlloc = incomingAllocIdx > -1 ? incomingEvt.carers[incomingAllocIdx] : undefined;
        if (!incomingAlloc) {
          // if the incoming event doesn't have a direct allocation for this carer, then we should definitely add ours now -
          // without even checking whether or not it has any newer information.
          // it might seem necessary to check the allocatedByRun array of other items in incoming.carers, and remove our
          // carer from there at the same time as adding their direct allocation.
          // however, this is not necessary - within Plait POC, we don't worry about maintaining allocatedByRun
          // according the the same rules as the back-end (search for findOrCreateAllocForLoggedInCarer).
          incomingEvt.carers.push(cachedAlloc);
          continue;
        }
        // if we have got this far, the incoming event DOES have an allocation for our carer, so our job is to give that
        // allocation any information from cachedAlloc that is "newer".  in the case of actual starts and finishes, we
        // will assume that - if we have these locally - the local data is "newer".  technically, it is possible that
        // actuals could be stuck on the phone and then manually-logged (later) from the office, but even if that did occur,
        // it still seems more relevant that the actuals logged by the carer take priority
        incomingAlloc.actualStart = cachedAlloc.actualStart || incomingAlloc.actualStart;
        incomingAlloc.actualFinish = cachedAlloc.actualFinish || incomingAlloc.actualFinish;
        // for the informed timestamps, local detailView and local summaryView should take priority, whilst the server's
        // value for informed.manual must be more accurate, so we'll not touch that here
        if (cachedAlloc.informed) {
          if (!incomingAlloc.informed) {
            incomingAlloc.informed = {};
          }
          incomingAlloc.informed.detailView = cachedAlloc.informed.detailView || incomingAlloc.informed.detailView;
          incomingAlloc.informed.summaryView = cachedAlloc.informed.summaryView || incomingAlloc.informed.summaryView;
        }
      }
      // Insert any adhoc events
      cached
        .filter((a) => a.eventClass === "AdHoc")
        .forEach((ah) => {
          this.insertAdHocInList(ah, eventList);
        });
    }
    if (dateUpdated) {
      dateUpdated.date = new Date();
    }
    return eventList;
  }

  private mergeOldStyleTaskCompletions(source: IExpressedEvent, destination: IExpressedEvent, carerId: string) {
    if (!source?.clients) {
      return; // shouldn't happen
    }
    for (const sourceClient of source.clients) {
      if (!(sourceClient.tasks?.length > 0)) {
        continue;
      }
      if (!destination.clients) {
        destination.clients = []; // shouldn't happen
      }
      const destClient = destination.clients.find((c) => (c.person = sourceClient.person));
      if (!destClient) {
        destination.clients.push(sourceClient); // shouldn't happen
        continue;
      }
      for (const task of sourceClient.tasks) {
        if (task.recordedBy === carerId) {
          const destTaskIdx = destClient.tasks.findIndex((t) => t.task === task.task);
          if (destTaskIdx === -1) {
            destClient.tasks.push(task); // shouldn't happen
          } else {
            destClient.tasks[destTaskIdx] = task;
          }
        }
      }
    }
  }

  public async updateCachedSessionDataStatusForMatchedSession(
    session: IBaseSessionForCarerLogin,
    serviceUserId: string,
    careWorkerId: string,
    item: IRawSessionBasedData,
    matchedSessionPropName: MatchedSessionPropName,
    sessionType: SessionType,
    newStatus: Partial<IFullSessionBasedRecordStatus> // Partial because the statusPerson will only be set below
  ): Promise<IRawSessionBasedData> {
    const ed = newStatus.eventDets;
    if (!ed?.eventId) {
      return item; // shouldn't happen
    }
    const cacheKey = this.getCacheKey(careWorkerId);
    const cacheObj = await this.readCache.getNoExpiry(cacheKey);
    let events: IExpressedCarerEvent[];
    let event: IExpressedCarerEvent;
    let matchedSessions: IBaseSessionForCarerLogin[];
    let matchedSession: IBaseSessionForCarerLogin;
    let cachedItem: IRawSessionBasedData;
    if (cacheObj) {
      events = cacheObj.obj;
      event = events.find((e) => {
        if (e.eventId === ed.eventId) {
          return true;
        }
        if (e.parentId && ed.recurId && e.instanceStart) {
          return (
            (e.parentId === ed.eventId || e.parentId === ed.parentEventId) &&
            new Date(e.instanceStart).valueOf() === ed.recurId.valueOf()
          );
        }
        return false;
      });
      if (event?.clients) {
        const alloc = event.clients.find((a) => a.person === serviceUserId);
        if (alloc) {
          matchedSessions = alloc[matchedSessionPropName];
          if (matchedSessions) {
            matchedSession = matchedSessions.find((s) => s.dtstart.valueOf() === session.dtstart.valueOf());
            if (matchedSession) {
              const cachedItems = matchedSession[sessionType] as IRawSessionBasedData[];
              if (cachedItems) {
                cachedItem = cachedItems.find((i) => {
                  return (
                    i._id === item._id ||
                    // It's possible that the card they've used to update the item's status was created
                    // from a stale cache item, and between then and when they actually performed
                    // the update, the carer event cache (and all of the matched sessions therein) was
                    // refreshed.  In this case, the cached item (i) would be the materialised med/task,
                    // whilst the item that has just been updated is still in unmaterialised form
                    i.when?.parent === item._id ||
                    // Alternatively, for a long event, they might have been sitting on the event-details
                    // page for more than two hours, and then gone to event-tasks or event-meds.  when this
                    // happens, the sessions cache will be updated (through the single client variant of
                    // the sessions cache refresh), potentially bringing down materialised tasks & meds),
                    // and thus the event/task cards will be created from these materialised records, while
                    // the event in the carer's event cache will still have the session matched with its
                    // unmaterialised med/task data.  this is essentially the opposite of the
                    // previous case, with i being unmaterialised while item is materialised.
                    i._id === item.when?.parent
                  );
                });
              }
            }
          }
        }
        if (matchedSession) {
          if (!cachedItem) {
            // if we found the relevant matched session against the event, but we didn't find the med/task on
            // that session, then add it now.
            // this can happen when a med is added at the back end shortly before the event is due to take place.
            // in this case, the event can have the matched session without that med, but when they click through
            // to the client-meds page, the cached med sessions for that client are refreshed, causing the
            // session cache (and therefore the client-meds page) to show the new med.  but, when its status is
            // updated, it's still not found against the matched-session of the event.
            // by adding it here, the meds button might have showed "0 / 6" when it was clicked, and then "7 / 7"
            // when the user goes back to event-details having marked all of the meds as done.
            cachedItem = Object.assign({}, item);
            matchedSession[sessionType].push(cachedItem);
          }
          this.sessionProvider.updateCachedSessionDataStatus(cachedItem, sessionType, careWorkerId, newStatus);
          // now we've updated (or added) cachedItem, we need to replace the entire cached obj for this to
          // actually achieve anything
          await this.readCache.store(events, cacheKey);
          // let the event details page know that the sessions matched to event have changed.  if it's currently
          // displaying that event, it will almost certainly need to refresh
          event.uniqueEventId = BaseEventProviderService.getUniqueEventId(event);
          this.observableEvents.publish(`event:${matchedSessionPropName}:updated`, event);
          return cachedItem;
        }
      }
    }
    this.logger.log(
      `Cannot find matched ${sessionType} session record ${item._id} against any of carer ${careWorkerId}'s events`,
      LogLvl.Error
    );
    const narrative1: string[] = [];
    const narrative2: string[] = [];
    try {
      narrative1.push(`Full record: ${JSON.stringify(item)}`);
      narrative1.push(`cacheKey: ${cacheKey}`);
      narrative1.push(`total cached events for this carer: ${events ? events.length : "none"}`);
      if (event) {
        narrative1.push(`event: ${JSON.stringify(event)}`);
      } else {
        narrative1.push(`ed: ${JSON.stringify(ed)}`);
        narrative1.push(
          `event not found in: ${JSON.stringify(
            // map the events to get rid of everything but the bare bones that may help with diagnosing the problem
            events.map((e) => {
              const result = {} as any;
              if (e.eventId) {
                result.eId = e.eventId;
              }
              if (e.parentId) {
                result.pId = e.parentId;
              }
              if (e.instanceStart) {
                result.is = e.instanceStart;
              }
              if (e.plannedStart) {
                result.ps = e.plannedStart;
              }
              return result;
            })
          )}`
        );
      }
      if (matchedSessions) {
        matchedSessions.sort((a, b) => a.dtstart.valueOf() - b.dtstart.valueOf());
        narrative2.push(`earliest matched session: ${matchedSessions[0].dtstart}`);
        narrative2.push(`latest matched session: ${matchedSessions[matchedSessions.length - 1].dtstart}`);
      }
      narrative2.push(`matchedSession: ${matchedSession ? JSON.stringify(matchedSession) : "not found"}`);
      narrative2.push(`cachedItem: ${cachedItem ? JSON.stringify(cachedItem) : "not found"}\n`);
    } catch (e) {
      // swallow any errors occurring while we generate a description of the real error!
    }
    // doing this in 2 parts because otherwise they can get be truncated.  as Sentry shows the breadcrumbs in
    // reverse order it makes slightly more sense to log 2 before 1.
    this.logger.log(narrative2.join("\n************************\n"), LogLvl.Error);
    this.logger.log(narrative1.join("\n************************\n"), LogLvl.Error);
    throw new Error(
      `Failed to find expected ${sessionType.toLocaleLowerCase()} session data item to update the status of in the carer-event-provider cache ` +
        JSON.stringify(item)
    );
  }

  public async populateEventLogsCache(eventList: IExpressedCarerEvent[]): Promise<void> {
    const clientIds = this.getUniqueClientIds(eventList);
    if (clientIds.length > 0 && !this.historicEventLogProvider.cachedHistory) {
      await this.historicEventLogProvider.preloadCache(clientIds);
    }
  }

  public async populateCareplanCache(eventList: IExpressedCarerEvent[]): Promise<void> {
    const earliestEventStarts = this.getEarliestEventStartsByClient(eventList);
    if (Object.keys(earliestEventStarts).length > 0) {
      await this.careplanProvider.preloadCache(earliestEventStarts);
    }
  }

  private getEarliestEventStartsByClient(events: IExpressedCarerEvent[]): IDatesByClient {
    const result: IDatesByClient = {};
    for (const event of events) {
      if (!event.clients) {
        continue;
      }
      const eventStart = new Date(event.plannedStart);
      for (const client of event.clients) {
        if (client.person) {
          if (!result[client.person] || eventStart < result[client.person]) {
            result[client.person] = eventStart;
          }
        }
      }
    }
    return result;
  }

  private getUniqueClientIds(events: IExpressedCarerEvent[]): string[] {
    const ids = new Set<string>();
    for (const event of events) {
      if (!event.clients) {
        continue;
      }
      for (const client of event.clients) {
        if (client.person) {
          ids.add(client.person);
        }
      }
    }
    return Array.from(ids);
  }

  public async handleEventUpdate(updatedEvent: IExpressedCarerEvent): Promise<void> {
    const allCachedEvents = await this.getAllCached();
    // The cache potentially contains arrays of events keyed against the id of the assigned carer.
    // (though there is almost certainly just one, as the phone belongs to one carer)
    // As we don't know which carer(s) updatedEvent might previously have been
    // assigned to, we need to check every carer....
    for (const oneCarersEvents of allCachedEvents) {
      // See whether the updated event was (at the time of the cache being populated)
      // assigned to this carer
      const eventIndex = oneCarersEvents.obj.findIndex(
        (e) => BaseEventProviderService.getUniqueEventId(e) === updatedEvent.uniqueEventId
      );
      if (eventIndex > -1) {
        // Removing the prefix from the cache key leaves us with the carer id
        // (the +1 is to remove the . from keys in the form E.xxxxxxxxxxxxxxxxxxxxxx)
        const carerId = oneCarersEvents.key.slice(this.cacheKeyPrefix.length + 1);
        // If this carer is still assigned to updatedEvent, then we need to update
        // their cached version of this event with the updated one. Otherwise, we need
        // to remove their cached version of the updated event entirely.
        const carerStillAssigned = !!updatedEvent.carers.find((c) => c.person && c.person === carerId);
        if (carerStillAssigned) {
          oneCarersEvents.obj[eventIndex] = updatedEvent;
        } else {
          oneCarersEvents.obj.splice(eventIndex, 1);
        }
        // Update the carer's cached event list in localstorage now we have modified it
        await this.readCache.store(oneCarersEvents.obj, oneCarersEvents.key);
      }
    }
  }

  private async retrieveEventFromCacheForApplyingLocalUpdates(
    event: IExpressedEvent,
    carerId: string
  ): Promise<{ cacheKey: string; cache; cachedEvent: IExpressedCarerEvent }> {
    const cacheKey = `${this.cacheKeyPrefix}.${carerId}`;
    const cache = await this.readCache.get(cacheKey);
    if (cache) {
      const cachedEvent = BaseEventProviderService.findEventInList(event, cache.obj);
      if (cachedEvent) {
        return { cacheKey, cache, cachedEvent };
      }
    }
    this.logger.log(`Cannot find event ${JSON.stringify(event)} in cache`, LogLvl.Error);
  }

  public async logActualsAtBackend(
    event: IExpressedEvent,
    carerAlloc: ICarerAllocation,
    taskCompletions: ICombinedTaskCompletions,
    logs: IEventLog[],
    isActualStart: boolean
  ): Promise<void> {
    // First, we'll update the cache, so we can do local refreshes before we get an update from the back end
    // the cache needs the updated carerAlloc (with start and / or finish), and if these have been provided
    // (which will only be the case for a log out), the updated old-style tasks and event log(s)
    const cached = await this.retrieveEventFromCacheForApplyingLocalUpdates(event, carerAlloc.person);
    const cachedEvent = cached?.cachedEvent;
    if (cachedEvent) {
      const cachedAllocIdx = cachedEvent.carers.findIndex((c) => c.person === carerAlloc.person);
      if (cachedAllocIdx === -1) {
        this.logger.log(
          `Cannot find carerAlloc for ${carerAlloc.person} in ${JSON.stringify(cachedEvent)})`,
          LogLvl.Error
        );
      } else {
        cachedEvent.carers[cachedAllocIdx] = carerAlloc;
      }
      if (!isActualStart) {
        if (logs?.length > 0) {
          // the logs should have already been medged into event.logs, so no need to refer to the
          // logs array passed to this function
          cachedEvent.logs = event.logs;
        }
        if (taskCompletions) {
          // the taskCompletions should have already been merged into event before this function
          // is called, so all we need to provide here is event
          this.mergeOldStyleTaskCompletions(event, cachedEvent, carerAlloc.person);
        }
      }
      await this.readCache.storeLocalChange(cached.cache.obj, cached.cacheKey, cached.cache.lastRead);
    }
    const idStr = this.getUrlForActualsLog(event, isActualStart);
    this.logger.log(`Logging ${idStr}`, LogLvl.Heavy);
    await super.cacheABackendWrite(URLType.Extended, idStr, { carerAlloc, taskCompletions, logs });
  }

  public getUrlForActualsLog(event: IExpressedEvent, isActualStart: boolean): string {
    const eventId = event.eventId || event.parentId;
    const instanceTime: number = event.instanceStart ? new Date(event.instanceStart).getTime() : 0;
    // Try to understand 7792
    if (isNaN(instanceTime)) {
      Sentry.captureMessage(`#7792 ${JSON.stringify(event)}`);
    }
    return `${eventId}/${instanceTime}/actual${isActualStart ? "Start" : "Finish"}`;
  }

  public async sendInterimLogOut(
    event: IExpressedEvent,
    carerAlloc: ICarerAllocation,
    logs: IEventLog[]
  ): Promise<void> {
    const eventId = event.eventId || event.parentId;
    const instanceTime: number = event.uniqueEventId === event.eventId ? 0 : new Date(event.instanceStart).getTime();
    const interimStr = `${eventId}/${instanceTime}/interimLogOut`;
    // If we already have an interim log queued for the same event, delete it before adding this one.  avoids
    // the potential for (completely pointlessly) flooding the server with interim logs for the same event when
    // the carer comes into signal after visiting the log page a number of times during the course of an event
    await this.writeCache.clearAnUpdateByUrl(interimStr, false);
    // Don't need to update the cache here, as we don't want the checked-out glyph to appear
    return super.cacheABackendWrite(URLType.Extended, interimStr, { carerAlloc, logs });
  }

  public async submitSupplementaryEventLog(event: IExpressedEvent, log: IEventLog, carerId: string): Promise<void> {
    log.submissionDate = new Date();
    event.logs.push(log);

    // First, we'll update the cache, so we can do local refreshes before we get an update from the back end
    const cached = await this.retrieveEventFromCacheForApplyingLocalUpdates(event, carerId);
    if (cached) {
      if (!cached.cachedEvent.logs) {
        cached.cachedEvent.logs = [log];
      } else {
        cached.cachedEvent.logs.push(log);
      }
      await this.readCache.storeLocalChange(cached.cache.obj, cached.cacheKey, cached.cache.lastRead);
    }

    const eventId = event.eventId || event.parentId;
    const instanceTime: number = event.uniqueEventId === event.eventId ? 0 : new Date(event.instanceStart).getTime();
    const idStr = `${eventId}/${instanceTime}/supplementaryLog`;
    await super.cacheABackendWrite(URLType.Extended, idStr, { log });
  }

  public logFirstViewedAtBackend(
    event: IExpressedCarerEvent,
    carerAlloc: ICarerAllocation,
    viewType: string
  ): Promise<void> {
    const eventId = event.eventId || event.parentId;
    const instanceTime = event.instanceStart ? new Date(event.instanceStart).getTime() : 0;
    const idStr = `${eventId}/${instanceTime}/${carerAlloc.person}/${viewType}View`;
    // When the server has finished processing this response, the status code returned will
    // indicate whether the event on the server matched what we sent (and therefore the
    // firstViewed flag was set), or didn't (and therefore it wasn't).  For now, we do
    // nothing with that response, but somewhere in the "nice to have" category, it would
    // be good if we proactively asked for the updated event when the server tells us
    // (through this response) that there is one.

    // We don't need to update the local cache with this
    // Use replaceExistingItem because we really don't need to be caching multiples of these
    // for the same event.  So if they're offline for an extended period - long enough for their
    // local cache to be refreshed (and thus the detailView to be overwritten) - we'll just end
    // up sending this once for each event.
    return super.cacheABackendWrite(URLType.Extended, idStr, event, { replaceExistingItem: true });
  }

  /**
   * Retrieve all of the reasons given by the specified carer for using the specified
   * logging method (or an over-threshold time adjustment in combination with that logging
   * method) during a visit to the given client.
   * This information is not currently cached, so all calls hit the backend.
   * @param clientId Which client's event was being logged into / out of when the
   * reasons were provided?
   * @param user Which user (carer) provided the reasons?
   * @param loggingMethod Which logging method was being used when the reasons
   * were provided.
   */
  public getPastLoggingMethodReasons(clientId: string, user: User, loggingMethod: LoggingMethod): Promise<string[]> {
    const url = `reasons/loggingMethod/${clientId}/${user._id}/${loggingMethod}`;
    return this.getFromBackEnd(URLType.Extended, url);
  }

  /**
   * Retrieve all of the reasons of the specified type (early or late check in or
   * check out) previously used when the given carer visited the given client.
   * This information is not currently cached, so all calls hit the backend.
   * @param clientId Which client's event was being logged into / out of when the
   * reasons were provided?
   * @param user Which user (carer) provided the reasons?
   * @param reasonPropName The name of the property that stores the required type
   * of reason at the backend.
   */
  public getPastEarlyOrLateEventLoggingReasons(
    clientId: string,
    user: User,
    reasonPropName: string
  ): Promise<string[]> {
    const url = `reasons/earlyOrLate/${clientId}/${user._id}/${reasonPropName}`;
    return this.getFromBackEnd(URLType.Extended, url);
  }

  /**
   * See whether we have any adhoc events cached on the phone (in which case we mustn't allow the cache to be cleared)
   */
  public async initialiseAdHocCount() {
    let counter = 0;
    const allCachedEvents = await this.getAllCached();
    for (const oneCarersEvents of allCachedEvents) {
      counter += oneCarersEvents.obj.filter((e) => e.uniqueEventId?.startsWith("-")).length;
    }
    this.adHocCount = counter;
    this.observableEvents.publish("updateCacheStatus");
  }

  public getAdHocCount(): number {
    return this.adHocCount;
  }
}
